<template>
	<div class="page-container">
		<p class="main-title" style="width: 25%;margin: 0;margin-bottom:5px;">
		{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<!-- <div class="page-desc">
	    
	 </div>  -->
	 <div style="display: flex;position: relative;">
		 <div class="label-box" style="width: 25%;text-align: center;">
		 	<div  style="width: 47%;float: left;margin-left: 10px;" v-for="(label,index) in labelData">
				<div v-if="!label.temp"  @click="preview(index)" :id="'tem_'+index">
		 			<div class="content" :class="{'active':label.isActive == true}">
		 				<el-image src="/images/qrcode.png"></el-image>
		 				<div class="field-name-box label">
		 					<span v-for="i in label.num" :style="{height:'calc(100% / i)'}" >字段名称{{i}}:xxxx</span>
		 				</div>
		 			</div> 
		 			<p class="label-name">模板{{index+1}}</p>
		 		</div>
		 		<div v-if="label.temp == 2">
		 			<div class="content" @click="preview(index)" :id="'tem_'+index" style="display: block;">
						<span style="display: block; border-bottom: 1px solid #909399;font-size: 10px;color: #606266;margin:0 auto;width: 90%;">标题字段：xxxxx </span>
						<div style="display: flex;">
							<el-image src="/images/qrcode.png"></el-image>
							<div class="field-name-box label">
								<span v-for="i in label.num"  style="height: 10px;">字段名称{{i}}:xxxx</span>
							</div> 
						</div>
		 			</div> 
		 			<p class="label-name">模板{{index+1}}</p>
		 		</div>
		 		<div v-if="label.temp == 3">
		 			<div class="content" style="display: block;"  @click="preview(index)" :id="'tem_'+index">
		 				<span v-if="label.isTop" style="display: block; font-size: 10px;color: #606266;margin: 0;padding-top: 5px;">标题字段：xxxxx </span>
		 				<el-image src="/images/qrcode.png" style="margin-top: 5px;"></el-image>
		 				<span v-if="!label.isTop" style="display: block;font-size: 10px;color: #606266;margin: 0;margin-bottom: 10px;">标题字段：xxxxx </span>
		 			</div>
		 			<p class="label-name">模板{{index+1}}</p>
		 		</div>
		 	</div>	
		 </div>
		 
		 <div class="preview" style="width:50%;">
		 	<div class="preview-content" id="preview" v-html="previewHtml"></div>
		 </div>
		 <div class="field-setting" style="position: relative;">
			 <p style="border-bottom: 2px solid #409eff;width: 90%;margin: 10px auto 10px auto;font-weight: bold;">配置标签字段</p>
			 <div v-for="(item,index) in fieldNum" style="display: flex;margin-bottom: 5px;">
				 <el-select v-model="fieldVal[index]['field_id']" placeholder="请选择字段" @change="(val)=>changeField(val,index)">
				     <el-option v-for="item in fieldData"  :label="item.title" :value="item.id"></el-option>
				 </el-select>
				 <el-checkbox @change="$forceUpdate()" style="line-height: 40px;" v-model="fieldVal[index]['title_status']">隐藏字段标题</el-checkbox>
			 </div>
			 <p style="border-bottom: 2px solid #409eff;width: 90%;margin: 20px auto 10px auto;font-weight: bold;">标签打印类型</p> 
			  <el-radio v-model="columns_number" :label="2">A4(两列标签)</el-radio>
			 <el-radio v-model="columns_number" :label="3">A4(三列标签)</el-radio>
			 <div style="bottom: 20px;width: 100%;position: absolute;"> 
				  <el-button style="width: 90%;" type="primary" @click="save()">保 存</el-button>
			 </div>
		 </div>
	 </div>
		
	</div>
</template>

<script>
	export default {
		inject: ["reload"],
		data() {
			return {
				emptyStatus:false,
				previewHtml:'',
				fieldVal:[
					{
						field_id:'',
						title_status:''
					}
				],
				labelData:[
					{
						num:1
					},{
						num:2
					},{
						num:3
					},{
						num:4
					},{
						temp:2,
						num:3
					},{
						temp:2,
						num:4
					},{
						temp:3,
						isTop:true,
						num:1
					},{
						temp:3,
						isTop:false,
						num:1
					}
				],
				fieldData:[],
				checkIndex:0,
				fieldNum:1,
				id:'',
				columns_number:2
			}
		},
		created() {
			this.getSetting()
		},
		mounted() {
			
		},
		methods: {
			getSetting(){
				this.$api.get('assetLabel/index',null,res=>{
					if(res.code == 200){
						let html =  document.getElementById('preview')
						let span = html.getElementsByTagName('span')
						this.fieldData = res.data.form_items
						if(res.data.info){
							this.id =  res.data.info.id
							this.fieldNum = res.data.info.field_content.length
							this.checkIndex = res.data.info.template
							this.columns_number = res.data.info.columns_number
							this.preview(this.checkIndex)
							this.fieldVal = res.data.info.field_content
												
							this.$nextTick(()=>{
								for(let j=0;j<this.fieldData.length;j++){
									for(let i=0;i<this.fieldVal.length;i++){
										if(this.fieldData[j]['id'] == this.fieldVal[i]['field_id']){
											let title_txt = ':xxxx'
											if(this.fieldVal[i]['title_status']) title_txt = '的值'
											span[i].innerText = this.fieldData[j]['title']+title_txt
										}
									}			
								}
							})
						}else{
							var d_outer = document.querySelector("#tem_0")
							this.previewHtml = d_outer.outerHTML
							this.fieldVal = []
						}
						
					}
				})
			},
			
			//选择标签字段
			changeField(val,index){
			
				let opt = {}
				 opt = this.fieldData.find((item)=>{
					return item.id  === val
				})
				let html =  document.getElementById('preview')
				let span = html.getElementsByTagName('span')
				for(let i=0;i<span.length;i++){
					if(i == index){
						span[i].innerText = opt.title+':xxxx'
					}
				}
					this.$forceUpdate()
				// console.log(this.fieldVal)
			},
			
			//点击预览
			preview(index){
				this.checkIndex = index
				this.fieldNum  = this.labelData[index]['num']
				 var d_outer = document.querySelector("#tem_"+index)
				this.previewHtml = d_outer.outerHTML
				this.fieldVal = []
			
	
				if(index == 4 || index == 5){
					this.fieldNum  = this.labelData[index]['num']+1
				}
				
				for(let i=0;i<this.fieldNum;i++){
					this.fieldVal[i] ={
						field_id:'',
						title_status:false
					}
				}
				
				for(let i=0;i<this.labelData.length;i++){
					this.labelData[i]['isActive'] = false
				}
				this.labelData[index]['isActive'] = !this.labelData[index]['isActive']
			
			},
			
			//保存
			save(){
				if(this.fieldVal.length < 1){
					this.$message.error('请先选择标签模板')
					return false
				}
				this.$api.post('assetLabel/add',{
					id:this.id,
					template:this.checkIndex,
					field_data:this.fieldVal,
					columns_number:this.columns_number
				},res=>{
					if(res.code == 200){
						this.$message.success(res.msg)
					} else{
						this.$message.error(res.msg)
					}
					this.reload()
				})
			}
		},
	}
</script>
<style scoped lang="scss">
	.page-container{
		background-color: #f3f7f7;
		overflow: hidden;
	}
	.label-box,.preview{
		background: white !important;
		// height:100vh;
		// height: 100%;
		min-height: calc(100vh - 200px);
		padding: 10px;
		.content{
			border: 1px solid #E4E7ED;
			display: flex;
			width: 100%;
			height: 99px;
			margin-right: 5px;
			.el-image{
				width:75px;
				height: 75px;
				margin-top: 10px;
			}
			.field-name-box{
				font-size: 10px;
				height: auto;
				align-self:center;
				text-align: left;
				span{
					display: block;
					color: #909399;
					margin: 10px 0px 0px 5px;
					
					// line-height: initial;
					// word-break: break-all;
					
				}
			}
			
		}
		.label-name{
			text-align: center;
			font-size: 12px;
			color: #606266;
		}
		.active{
				border: 0px;
				border: 1px solid #409EFF;
			}
	}
	.preview{
		background: transparent !important;
		// position: relative;
		.preview-content{
			position: absolute;
			top:10%;
			left:50%;
			transform:translate(-50%,-50%);
		}
		.content{
			background-color: white !important;
			width: 250px;
			margin: 0 auto;
			// min-height: 120px;
			text-align: center;
			// p{
			// 	text-align: center;
			// 	font-size: 14px !important;
			// }
			// .field-name-box{
			// 	font-size: 12px !important;
			// 	color: #303133;
			// }
		}
	}
	.field-setting{
		width:25%;
		background-color: white !important;
		// height:calc(100vh - 50px);
		min-height: calc(100vh - 200px);
		padding-right: 5px;
		text-indent: 20px;
	
	}
</style>
